import React from 'react';
import { useRouter } from 'next/router';
import { UserTypeEnum } from '@spa-cars/models';
import { useUser } from '../../hooks';

const getUserFirstRoute = (userType: UserTypeEnum) => {
  switch (userType) {
    case 'company':
      return '/app/vehicle-fleet';

    case 'admin_manager':
      return '/app/discounts';

    case 'inventory_manager':
      return '/app/products';

    case 'admin':
      return '/app/walkin';

    case 'superadmin':
      return '/app/walkin';

    default:
      return '/';
  }
};

function HomePage() {
  const router = useRouter();
  const [user] = useUser();
  React.useEffect(() => {
    if (user?._id) {
      router?.push(getUserFirstRoute(user?.userType));
    } else {
      router?.push('/');
    }
  }, [user]);
  return <div />;
}

export default HomePage;
